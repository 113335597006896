import React from 'react';
import "./GDPRModal.scss"

const GDPRModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div id="GDPR-modal">
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div class="modal-title">
                        <h2 className="bold-text">
                            <span className="bold-text">Zásady zpracování osobních údajů</span>
                        </h2>
                    </div>
                    <div>
                        <p>Kontaktní údaje správce</p>
                    </div>
                    <div>
                        <ul class="contact-information">
                            <li><span className="bold-text">Název: coalios s. r. o.</span></li>
                            <li><span className="bold-text">Adresa sídla: Koliště 1912/13, 602 00 Brno-střed-Černá Pole</span></li>
                            <li>IČO: <span className="bold-text">11998831</span></li>
                            <li>E-mail: <span className="bold-text">info@coalios.cz</span></li>
                            <li>Telefon: <span className="bold-text">+420 602 326 793</span></li>
                        </ul>
                    </div>
                    <div>Zpracování osobních údajů probíhá to v souladu s Nařízením Evropského parlamentu a Rady (EU) 2016/679 ze dne 27. 4. 2016 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (dále jen „Nařízení“), zákona o zpracování osobních údajů a zákona č. 480/2004 Sb., o některých službách informační společnosti, ve znění pozdějších předpisů.</div>
                    <div style={{ marginTop: "25px" }} className="list-section">
                        <span>1. Pojmy</span>
                        <div className="list-section-li">
                            <p>
                                <span className="bold-text">Subjekt údajů</span>: Fyzická osoba (spotřebitel i osoba samostatně výdělečně činná), k níž se osobní údaje vztahují (dále také „<span className="bold-text">Vy</span>“ či „<span className="bold-text">zákazník</span>“);
                            </p>
                            <p>
                                <span className="bold-text">Osobní údaj</span>: Veškeré informace o identifikovaném nebo identifikovatelném zákazníkovi; identifikovatelným zákazníkem je fyzická osoba, kterou lze přímo či nepřímo identifikovat, zejména odkazem na určitý identifikátor, například jméno, identifikační číslo, lokační údaje, síťový identifikátor nebo na jeden či více zvláštních prvků fyzické, fyziologické, genetické, psychické, ekonomické, kulturní nebo společenské identity této fyzické osoby (dále také „<span className="bold-text">údaje</span>“ či „<span className="bold-text">informace</span>“);
                            </p>
                            <p>
                                <span className="bold-text">Správce</span>: Subjekt, který určuje účel a prostředky zpracování osobních údajů, provádí zpracování a nese za toto zpracování odpovědnost. Správcem osobních údajů je <span className="bold-text">coalios s.r.o.</span> (dále také „<span className="bold-text">my</span>“);
                            </p>
                            <p>
                                <span className="bold-text">Zpracovatel</span>: Subjekt, který na základě zákona nebo z pověření správce zpracovává osobní údaje pro správce, a to na základě smlouvy o zpracování osobních údajů (dále také „<span className="bold-text">obchodní partner</span>“ či „<span className="bold-text">partner</span>“);
                            </p>
                            <p>
                                <span className="bold-text">Webové stránky</span>: Webové stránky dostupné na <span className="bold-text">www.coalios.cz</span>;
                            </p>
                            <p>
                                <span className="bold-text">Účel zpracování osobních údajů</span>: Důvod, proč jsou osobní údaje zpracovávány. Takovým důvodem může být např. plnění smlouvy, správa uživatelských účtů, vyřizování podnětů a stížností, zasílání obchodních sdělení (newsletterů) či zobrazování reklam na základě zájmů zákazníků;
                            </p>
                            <p>
                                <span className="bold-text">Cookies</span>: Krátké textové soubory, které si ukládá Váš webový nebo mobilní prohlížeč. Většina souborů cookie obsahuje jedinečný identifikátor, tzv. ID souboru cookie. Jedná se o řetězec znaků přiřazovaný webovými stránkami a servery tomu prohlížeči, který soubor cookie uložil. To umožňuje webovým stránkám a serverům jednotlivé prohlížeče rozlišit a identifikovat. Soubory cookie se používají pro zlepšení fungování webových stránek, vyhodnocení jejich návštěvnosti a za účelem lepšího cílení marketingových aktivit. Procházíte-li naše webové stránky, předpokládáme, že souhlasíte s používáním těchto souborů.
                            </p>
                            <p>
                                <span className="bold-text">Třetí země</span>: Státy mimo Evropský hospodářský prostor, jehož součástí jsou především členské země Evropské unie a Island, Lichtenštejnsko a Norsko.
                            </p>
                        </div>
                    </div>
                    <div className="list-section">
                        <span>2. Jaké osobní údaje jsou zpracovávány?</span>
                        <div className="list-section-li">
                            <p style={{ marginBottom: "0px" }}>My a jeho smluvní zpracovatelé v návaznosti na příslušný právní titul a účel zpracování zpracovávají následující osobní údaje, respektive kategorie osobních údajů:</p>
                            <ol className="personal-information-ol">
                                <li>a)  <span className="bold-text">identifikační a adresní údaje</span>: např. jméno, příjmení, doručovací nebo jiná kontaktní adresa, adresa odběrného místa, sídlo podnikání, IČO, DIČ;</li>
                                <li>b)  <span className="bold-text">elektronické kontaktní údaje</span>: např. telefonní číslo, e-mailová adresa;</li>
                                <li>c)  <span className="bold-text">jiné elektronické údaje</span>: IP adresa, cookies;</li>
                                <li>d)  <span className="bold-text">další osobní údaje spojené se smluvním vztahem</span>: číslo bankovního účtu, historie objednávek;</li>
                                <li>e)  <span className="bold-text">další osobní údaje</span>: typicky údaje poskytnuté zákazníkem v objednávkovém formuláři či v jiných dokumentech a při komunikaci s námi, a to včetně pozdějších aktualizací.</li>
                            </ol>
                        </div>
                    </div>

                    <div className="list-section">
                        <span>3. Jaký je původ osobních údajů</span>
                        <div className="list-section-li">
                            <p style={{ marginBottom: "0px" }}>Zpracováváme údaje, které nám sdělíte např. při objednávce našich služeb, registraci uživatelského účtu, komunikaci s námi či přihlášením k odběru newsletteru. Typicky jde o</p>
                            <ul>
                                <li> <span className="bold-text">identifikační a adresní údaje;</span></li>
                                <li> <span className="bold-text">elektronické kontaktní údaje;</span></li>
                                <li> <span className="bold-text">další osobní údaje spojené se smluvním vztahem.</span></li>
                            </ul>
                            <p style={{ marginBottom: "0px" }}>A dále údaje, které získáváme automaticky na základě toho, že procházíte naše webové stránky. Typicky jde o:</p>
                            <ul>
                                <li><span className="bold-text">jiné elektronické údaje:</span>
                                    <ul style={{ listStyleType: "square" }}>
                                        <li>cookies</li>
                                        <li>webová stránka, z níž jste na naše webové stránky přišli;</li>
                                        <li>IP adresa;</li>
                                        <li>datum přístupu a doba přístupu;</li>
                                        <li>vyhledávací dotazy;</li>
                                        <li>kód odpovědi http a https;</li>
                                        <li>přenášené skupiny dat;</li>
                                        <li>údaj o prohlížeči a operačním systému počítače.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="list-section">
                        <span>4. Proč jsou osobní údaje zpracovávány?</span>
                        <div className="list-section-li">
                            <p style={{ marginBottom: "0px" }}>Vaše osobní údaje mohou být zpracovávány pro následující účely:</p>
                            <ul>
                                <li><span className="bold-text">Komunikace se zákazníky, hodnocení spokojenosti, publikace recenzí, doporučení knih, vyřizování podnětů, stížností a reklamací</span></li>
                                <li><span className="bold-text">Zasílání obchodních sdělení a nabídka našeho zboží</span></li>
                                <li><span className="bold-text">Přímý marketing a tvorba personalizovaného obsahu a reklamy</span></li>
                                <li><span className="bold-text">Zlepšování kvality našeho zboží a služeb, analýzy návštěvnosti našich webových stránek a Vašeho chování na webových stránkách</span></li>
                                <li><span className="bold-text">Provozování zákaznické soutěže a doručování výher a Plnění ostatních právních povinností</span></li>
                            </ul>
                            <p style={{ marginBottom: "0px" }}>Vaše osobní údaje mohou být zpracovávány na základě následujících právních důvodů:</p>
                            <ul>
                                <li><span className="bold-text">Oprávněný zájem správce na zasílání obchodních sdělení</span></li>
                                <li><span className="bold-text">Souhlas se zasíláním obchodních sdělení</span></li>
                            </ul>
                            <p style={{ marginBottom: "10px" }}>Zpracování osobních údajů pro účely plnění smluvního vztahu, účetní a daňové účely a plnění ostatních právních povinností jsou zákonnými či smluvními požadavky. Hodláte-li prostřednictvím našich webových stránek učinit objednávku, máte povinnost nám pro tyto účely své osobní údaje poskytnout.</p>
                        </div>

                    </div>

                    <div className="list-section">
                        <span>5. Jak dlouho jsou osobní údaje zpracovávány?</span>
                        <div className="list-section-li">
                            <p style={{ marginBottom: "0px" }}>Vaše osobní údaje jsou zpracovávány:</p>
                            <ul>
                                <li>po dobu nezbytnou k výkonu práv a povinností vyplývajících ze smluvního vztahu mezi Vámi a námi a uplatňování nároků z těchto smluvních vztahů (4 roky);</li>
                                <li>po dobu nezbytnou k plnění právní povinnosti (účetní doklady 5 let, daňové doklady 10 let);</li>
                                <li>po dobu trvání našeho oprávněného zájmu na zasílání obchodních sdělení (2 roky od posledního otevření obchodního sdělení);</li>
                                <li>po dobu trvání Vašeho souhlasu (nejdéle po dobu 4 roky od jeho udělení, případně 4 roky od poslední objednávky).</li>
                            </ul>
                        </div>
                    </div>

                    <div className="list-section">
                        <span>6. Komu jsou osobní údaje zpřístupněny?</span>
                        <div className="list-section-li">
                            <p style={{ marginBottom: "0px" }}>Přístup k Vašim osobním údajům mohou mít následující kategorie našich partnerů (příjemců):</p>
                            <ul>
                                <li><span className="bold-text">Poskytovatelé zabezpečení a integrity našich služeb a webových stránek</span></li>
                                <li><span className="bold-text">Poskytovatelé analytických služeb</span></li>
                                <li><span className="bold-text">Poskytovatelé asistenčních služeb pro zákaznickou podporu,</span></li>
                                <li><span className="bold-text">Poskytovatelé platebních brán (poskytovatelé platebních karet)</span></li>
                                <li><span className="bold-text">Poskytovatelé právních služeb, advokáti</span></li>
                                <li><span className="bold-text">Poskytovatelé tiskových a poštovních služeb</span></li>
                                <li><span className="bold-text">Partneři spolupracující s námi ve věrnostních programech, při pořádání konferencí, seminářů a jiných akcí</span></li>
                                <li><span className="bold-text">Partneři, kteří pro nás zajišťují přímý marketing a partneři a provozovatelé technických řešení, díky kterým Vám můžeme zobrazovat personalizovaný obsah a reklamu</span></li>
                                <li><span className="bold-text">Orgány veřejné správy</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="list-section">
                        <span>7. Jsou osobní údaje předávány mimo EU?</span>
                        <div className="list-section-li">
                            <p style={{ marginBottom: "0px" }}>Správce nemá úmysl předávat osobní údaje do třetí země (mimo Evropský hospodářský prostor) nebo mezinárodní organizaci. Příjemci osobních údajů ve třetích zemích jsou:</p>
                            <ul>
                                <li><span className="bold-text">Partneři, kterým poskytujeme údaje za účelem analýzy návštěvnosti našich webových stránek, Vašeho chování na webových stránkách a obchodních konverzí</span></li>
                                <li><span className="bold-text">Poskytovatelé IT služeb a hostingu, vč. cloudových služeb</span></li>
                                <li><span className="bold-text">Poskytovatelé mailingových služeb</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="list-section">
                        <span>8. Jakým způsobem jsou osobní údaje zpracovávány?</span>
                        <div className="list-section-li">
                            <p>Osobní údaje jsou zpracovávány manuálně i automatizovaně. O všech činnostech zpracování vedeme řádné záznamy v souladu s příslušnými právními předpisy. </p>
                            <p>Nejste předmětem žádného rozhodnutí založeného výhradně na automatizovaném zpracování, včetně profilování, které by pro Vás mělo právní účinky nebo by se Vás obdobným způsobem významně dotýkalo. Netvoříme/Tvoříme profily z Vašich osobních údajů za účelem rozboru či předvídání Vašich preferencí, zájmů, ekonomické situace, spolehlivosti, místa, kde se nacházíte nebo Vašeho pohybu (typickým příkladem profilování je monitorování chování návštěvníků webových stránek za účelem sledování jejich preferencí tak, aby je mohl obchodník v budoucnu oslovit s nabídkou ušitou právě jim na míru).</p>
                        </div>
                    </div>

                    <div className="list-section">
                        <span>9. Jaká jsou práva subjektů údajů?</span>
                        <div className="list-section-li">
                            <p>Při realizaci Vašich práv se na nás obracejte prostřednictvím našich kontaktních údajů, které jsou uvedeny v úvodu těchto zásad. </p>
                            <p style={{ marginBottom: "0px" }}>V souvislosti se zpracováním Vašich osobních údajů máte následující práva (čl. 15 až 21 GDPR):</p>
                            <ul>
                                <li><span className="bold-text">Právo na přístup k osobním údajům</span></li>
                                <li><span className="bold-text">Právo na opravu nepřesných a doplnění neúplných osobních údajů</span></li>
                                <li><span className="bold-text">Právo na výmaz osobních údajů</span></li>
                                <li><span className="bold-text">Právo na omezení zpracování osobních údajů</span></li>
                                <li><span className="bold-text">Právo na přenositelnost údajů</span></li>
                                <li><span className="bold-text">Právo vznést námitku proti zpracování</span></li>
                                <li><span className="bold-text">Právo na informace o automatizovaném rozhodování, včetně profilování</span></li>
                            </ul>
                            <p>Zpracováváme-li Vaše osobní údaje na základě Vašeho souhlasu, máte <span className="bold-text">právo kdykoliv tento souhlas odvolat</span>.</p>
                            <p>Dále máte právo <span className="bold-text">podat stížnost u dozorového orgánu</span>, kterým je Úřad pro ochranu osobních údajů, se sídlem Pplk. Sochova 27, 170 00 Praha 7, <nobr>tel.: 234 665 111</nobr>, web: <a href="https://uoou.gov.cz/" target="_blank">www.uoou.cz</a>.</p>
                        </div>
                    </div>

                    <div className="list-section">
                        <span>10. Jak jsou zpracovávány soubory cookie?</span>
                        <div className="list-section-li">
                            <p style={{ marginBottom: "0px" }}>Zpracovávané soubory cookie lze dělit dle platnosti na:</p>
                            <ul>
                                <li><span className="bold-text">dočasné cookies</span> (tzv. <span className="italic-text">session cookies</span>), které zůstávají uloženy ve Vašem prohlížeči pouze do doby, než zavřete Váš prohlížeč,</li>
                                <li><span className="bold-text">trvalé cookies</span> (tzv. <span className="italic-text">persistent cookies</span>), které zůstávají dlouhodobě uloženy ve Vašem prohlížeči, dokud neuplyne jejich životnost nebo dokud je manuálně neodstraníte (doba uložení souborů cookie ve Vašem prohlížeči závisí na nastavení samotné cookie a nastavení Vašeho prohlížeče).</li>
                            </ul>
                            <p style={{ marginBottom: "0px" }}>A podle funkcí na:</p>
                            <ul>
                                <li><span className="bold-text">esenciální</span>, které jsou nezbytné pro funkčnost našich webových stránek,</li>
                                <li><span className="bold-text">preferenční</span>, které umožňují, aby si naše webové stránky zapamatovala informace, které mění, jak se webová stránka chová nebo jak vypadá (např. preferovaný jazyk nebo region, kde se nacházíte), tyto cookies nejsou nezbytně nutná pro fungování našich webových stránek, ale zvyšují funkčnost a praktičnost jejich užívání,</li>
                                <li><span className="bold-text">analytické</span>, které nám pomáhají s analýzou Vašeho zážitku na našich webových stránkách (tzv. <span className="italic-text">User Experience = uživatelský zážitek</span>) a díky nimž rozumíme, jak používáte naše webové stránky,</li>
                            </ul>
                            <p>Nepoužíváme cookies třetích stran, na základě nichž probíhá sledování více internetových stránek, abychom Vám mohli poskytovat personalizovaný obsah a reklamu na webových stránkách třetích stran a jiných prodejních kanálech. </p>
                        </div>
                    </div>

                    <div className="list-section">
                        <span>11. Jsou zpracovávány i údaje o dětech?</span>
                        <div className="list-section-li">
                            <p>
                                Naše webové stránky nejsou určeny dětem mladším 16 let. Jejich osobní údaje tedy úmyslně neshromažďujeme. Pokud zjistíme, že jsme nedopatřením získali osobní údaje o dětech mladších 16 let, provedeme kroky k tomu, abychom tyto údaje co nejrychleji vymazali, kromě případů, kdy jsme příslušným zákonem vázáni si je ponechat.
                            </p>
                        </div>
                    </div>

                    <div className="list-section">
                        <span>12. Závěr</span>
                        <div className="list-section-li">
                            <p>
                                <span className='bold-text'>Právní předpisy i naše obchodní strategie a s ní související způsoby zpracování Vašich osobních údajů se mohou měnit. Pokud se tyto zásady rozhodneme aktualizovat, umístíme změny na našich webových stránkách a budeme Vás o těchto změnách informovat. V případech, kdy má dojít k zásadnější změně těchto zásad, či v případě, kdy nám tak uloží zákon, budeme Vás informovat předem. Žádáme Vás, abyste si tyto zásady pečlivě přečetli a při další komunikaci s námi či užívání našich webových stránek tyto zásady pravidelně kontrolovali.</span>
                            </p>
                        </div>
                    </div>

                    <div />
                    <div className="btn-content">
                        <button className="modal-btn" onClick={onClose}>Zavřít</button>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default GDPRModal;
