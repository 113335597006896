import React, { useState } from "react"
import { FormattedMessage, Link } from "gatsby-plugin-intl"

import "./Footer.scss"

import logo from "../../images/logo/logo_rgb_white.png"
import GDPRModal from "../Modals/GDPRModal"
import CookieModal from "../Modals/CookieDeclaration"

const Footer = () => {
    const [date] = useState(new Date().getFullYear());
    const [isGDPRModalOpen, setIsGDPRModalOpen] = useState(false);
    const [isCookieModalOpen, setIsCookieModalOpen] = useState(false);

    const toggleGDPRModal = () => setIsGDPRModalOpen(!isGDPRModalOpen);
    const toggleCookieModal = () => setIsCookieModalOpen(!isCookieModalOpen);

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-auto">
                        <Link to="/" className="navbar-brand">
                            <img src={logo} height="30px" className="" alt="Coalsoft" />
                        </Link>
                        <p className="pt-2">
                            <FormattedMessage id="footer.text" />
                        </p>
                    </div>
                    <div className="col-md">
                        <ul className="list-inline list-unstyled text-md-right socials">
                            <li className="list-inline-item">
                                <a
                                    className=""
                                    href="https://www.linkedin.com/company/coalios/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-linkedin"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a
                                    className=""
                                    href="https://www.instagram.com/coalfamily_/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a
                                    className=""
                                    href="https://www.facebook.com/coalfamily"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i className="fab fa-facebook-square"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <span className="gdpr-policy-link" onClick={toggleGDPRModal} style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }}>
                        Zásady pro ochranu osobních údajů
                    </span>
                    <span className="gdpr-policy-link" onClick={toggleCookieModal} style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }}>
                        Zásady používání cookies
                    </span>
                    <span className="col footer-copyright">© {date || ""} coalios s.r.o.</span>
                </div>

                <GDPRModal isOpen={isGDPRModalOpen} onClose={toggleGDPRModal} />
                <CookieModal isOpen={isCookieModalOpen} onClose={toggleCookieModal} />
            </div>
        </footer>
    )
}

export default Footer
