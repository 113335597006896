import React, { useEffect } from 'react';
import "./CookieDeclaration.scss"

const CookieModal = ({ isOpen, onClose }) => {
    useEffect(() => {
        if (isOpen) {
            const script = document.createElement('script');
            script.src = "https://consent.cookiebot.com/a108b8cf-7e99-4e2f-9b63-cad663302445/cd.js";
            script.type = "text/javascript";
            script.async = true;

            // Select the modal-content element where the script will be appended
            const modalContent = document.querySelector("#cookie-modal #cookie-declaration-content");
            if (modalContent) {
                modalContent.appendChild(script);
            }

            return () => {
                // Make sure modalContent and script are valid before attempting to remove the script
                if (modalContent && script.parentNode === modalContent) {
                    modalContent.removeChild(script);
                }
            };
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div id="cookie-modal">
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div class="modal-title">
                        <h2 className="bold-text">
                            <span className="bold-text">Zásady používání cookies</span>
                        </h2>
                    </div>
                    <div id="cookie-declaration-content">
                        {/* The script will be injected here when modal opens */}
                    </div>
                    <div className="btn-content">
                        <button className="modal-btn" onClick={onClose}>Zavřít</button>
                    </div>
                </div>

            </div>
        </div >
    );
};

export default CookieModal;
